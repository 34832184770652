import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"


const Header = ({ siteTitle }) => (
  <header className="bg-green-500">
    <div className="container mx-auto mx-4 pt-1 pb-2"
    >
      <Link to='/'>
        <h1 className="text-4xl text-white">{siteTitle}</h1>
      </Link>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
